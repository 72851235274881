<template>
  <div class="menu_data">
    <ToolBar />
    <div>
      <v-container>
        <!-- ข้อมูล -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ข้อมูลลูกค้า</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_data_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="amber lighten-5">
                  <v-icon
                    x-large
                    color="amber darken-2"
                  >{{item.icon}}</v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- ตีออกเ -->
        <!-- <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ตีออก</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_export_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                    v-text="item.icon"
                  ></v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row> -->
        <!-- ตั้งค่า -->
        <!-- <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ตั้งค่างวด</v-subheader
            ></v-col
          >
          <v-col
            cols="3"
            md="3"
            v-for="(item, i) in menu_setting_item"
            :key="i"
          >
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                    v-text="item.icon"
                  ></v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-divider></v-divider> -->
        <!-- รายงาน -->
        <!-- <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >รายงาน</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_report_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                    v-text="item.icon"
                  ></v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row> -->
        <!-- <v-divider></v-divider> -->
        <v-card height="50" flat> </v-card>
      </v-container>
    </div>

    <BottomNavigation ref="b_nav" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import ToolBar from "@/components/Layouts/ToolBar";
import BottomNavigation from "@/components/Layouts/BottomNavigation";

export default {
  name: "Menu_Data",
  mixins: [globalFunctionMixin],
  components: {
    BottomNavigation,
    ToolBar,
    // SideBar,
  },
  mounted() {
    this.ckLogin();
    this.$refs.b_nav.SetID(1);
    window.scrollTo(0,0);
  },
  data: () => ({
    menu_data_item: [
      { text: "ทั่วไป", icon: "mdi-account-supervisor", route: "/line_data" },
            {
        text: "งวดปัจจุบัน",
        icon: "mdi-application-settings",
        route: "/",
      },
      {
        text: "บัญชีงวด",
        icon: "mdi-clipboard-text-clock-outline",
        route: "/",
      },
      { text: "บัญชีรวม", icon: "mdi-file-table", route: "/" },
      { text: "อัตราจ่าย", icon: "mdi-percent", route: "/" },
      { text: "บันทึก", icon: "mdi-content-save-outline", route: "/" },
      { text: "ใบปะหน้า", icon: "mdi-file", route: "/" },
      { text: "ผู้ดูแล", icon: "mdi-account-multiple", route: "/" },
    ],
    menu_export_item: [
      //   { text: "ตั้งเจ้ามือ", icon: "mdi-account-tie", route: "/limit_number" },
      //   {
      //     text: "การตีออก",
      //     icon: "mdi-chart-areaspline",
      //     route: "/limit_number",
      //   },
      //   { text: "โพยตีออก", icon: "mdi-file", route: "/export_list" },
      //   { text: "ยอดตีออก", icon: "mdi-cash-100", route: "/limit_number" },
    ],
    menu_setting_item: [
      //   { text: "เปิด/ปิดงวด", icon: "mdi-tune", route: "/limit_number" },
      //   {
      //     text: "ประมวลผล",
      //     icon: "mdi-license",
      //     route: "/limit_number",
      //   },
    ],
    menu_report_item: [
      //   {
      //     text: "ใบปะหน้า",
      //     icon: "mdi-microsoft-onenote",
      //     route: "/limit_number",
      //   },
      //   {
      //     text: "บัญชีรับ-จ่าย",
      //     icon: "mdi-text-account",
      //     route: "/limit_number",
      //   },
    ],
    test: true,
  }),
  methods: {},
};
</script>

<style></style>
